$error-color: #c30045;

/* Custom */
body {
    .p-inputtext.errors {
        border-color: $error-color;
    }

    .p-inputtext.ng-dirty.ng-invalid,
    p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
    p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
    p-calendar.ng-dirty.ng-invalid > .p-inputtext,
    p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
    p-checkbox.ng-dirty.ng-invalid .p-chkbox-box,
    p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box,
    p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,
    p-listbox.ng-dirty.ng-invalid .p-inputtext,
    p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
    p-spinner.ng-dirty.ng-invalid > .p-inputtext,
    p-selectbutton.ng-dirty.ng-invalid .p-button,
    p-togglebutton.ng-dirty.ng-invalid .p-button {
        border-bottom-color: $error-color;
    }

    .md-inputfield input.ng-dirty.ng-invalid ~ label {
        color: $textColor;
    }

    .md-inputfield .p-message.p-messages-error {
        position: absolute;
        left: 1.25em;
        top: 1.25rem;
        font-size: 0.75em;
    }
}

/* Exception */
.exception-body {
    &.error-page {
        .exception-type {
            background-color: $error-color;

            img {
                width: 100%;
            }
        }

        .exception-panel {
            i {
                color: #f79a84;
            }
        }
    }
}

body {
    /* Data */
    .p-datatable {
        .p-datatable-data {
            tr {
                td {
                    &.p-state-error {
                        background-color: $error-color;
                        border-color: $error-color;
                        color: #ffffff;
                    }
                }
            }

            tr.p-state-error {
                background-color: $error-color;
                border-color: $error-color;
                color: #ffffff;

                .p-inputtext,
                .p-inputtext.p-state-error {
                    border-color: #ffffff;
                }
            }
        }
    }

    /* Forms */
    .p-inputtext {
        &.p-state-error {
            border-color: $inputBorderErrorColor;
        }
    }

    .md-inputfield {
        .p-message {
            &.p-messages-error {
                color: $inputErrorTextColor;
            }
        }
    }

    .p-material-message {
        &.p-message {
            &.p-messages-error {
                color: $error-color;
            }
        }
    }

    /* Messages */
    .p-messages {
        &.p-messages-error {
            background-color: $error-color;
            border-color: $error-color;
            color: #ffffff;
        }
    }
}
