.p-multiselect {
    .p-multiselect-label,
    .p-multiselect-trigger {
        background-image: none;
        background: transparent;
    }

    .p-multiselect-label {
        border: 0 none;
    }

    &:not(.p-disabled).p-focus {
        box-shadow: inset 0 0 0 1px $inputFocusBorderColor, inset 0 0 0 1px $inputFocusBorderColor, inset 0 0 0 1px $inputFocusBorderColor,
            inset 0 0 0 1px $inputFocusBorderColor;
    }
}

.p-multiselect-item {
    .p-ink {
        background-color: rgba($primaryColor, 0.16);
    }
}

.p-input-filled {
    .p-multiselect {
        @include filled-input-wrapper();

        .p-multiselect-label {
            &:hover {
                background-image: none;
                background: transparent;
            }
        }
    }
}
