.p-accordion {
    .p-accordion-header {
        .p-accordion-header-link {
            padding: $accordionHeaderPadding;
            border: $accordionHeaderBorder;
            color: $accordionHeaderTextColor;
            background: $accordionHeaderBg;
            font-weight: $accordionHeaderFontWeight;
            border-radius: $borderRadius;
            transition: $listItemTransition;

            .p-accordion-toggle-icon {
                margin-right: $inlineSpacing;
            }
        }

        &:not(.p-disabled) {
            .p-accordion-header-link {
                &:focus {
                    @include focused();
                }
            }
        }

        &:not(.p-highlight):not(.p-disabled):hover {
            .p-accordion-header-link {
                background: $accordionHeaderHoverBg;
                border-color: $accordionHeaderHoverBorderColor;
                color: $accordionHeaderTextHoverColor;
            }
        }

        &:not(.p-disabled).p-highlight {
            .p-accordion-header-link {
                background: $accordionHeaderActiveBg;
                border-color: $accordionHeaderActiveBorderColor;
                color: $accordionHeaderTextActiveColor;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:hover {
                .p-accordion-header-link {
                    border-color: $accordionHeaderActiveHoverBorderColor;
                    background: $accordionHeaderActiveHoverBg;
                    color: $accordionHeaderTextActiveHoverColor;
                }
            }
        }
    }

    .p-accordion-content {
        padding: $accordionContentPadding;
        border: $accordionContentBorder;
        background: $accordionContentBg;
        color: $accordionContentTextColor;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }

    p-accordiontab {
        .p-accordion-tab {
            margin-bottom: $accordionSpacing;
        }

        @if $accordionSpacing == 0 {
            .p-accordion-header {
                .p-accordion-header-link {
                    border-radius: 0;
                }
            }

            .p-accordion-content {
                border-radius: 0;
            }

            &:not(:first-child) {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-top: 0 none;
                    }

                    &:not(.p-highlight):not(.p-disabled):hover,
                    &:not(.p-disabled).p-highlight:hover {
                        .p-accordion-header-link {
                            border-top: 0 none;
                        }
                    }
                }
            }

            &:first-child {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-top-right-radius: $borderRadius;
                        border-top-left-radius: $borderRadius;
                    }
                }
            }

            &:last-child {
                .p-accordion-header:not(.p-highlight) {
                    .p-accordion-header-link {
                        border-bottom-right-radius: $borderRadius;
                        border-bottom-left-radius: $borderRadius;
                    }
                }

                .p-accordion-content {
                    border-bottom-right-radius: $borderRadius;
                    border-bottom-left-radius: $borderRadius;
                }
            }
        }
    }
}
