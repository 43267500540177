.p-dialog-content {
    overflow-y: auto !important;
    padding-top: 1em;

    .dialog-list {
        margin-bottom: 1em;
    }
}

.fp-dialog {
    width: 450px;
}
