/* Layout */
/* Topbar */
/* Menus */
/* Add your variable customizations of layout here */ /* Used Transfonter (https://transfonter.org/) */
@font-face {
  font-family: "Belfius";
  src: url("/assets/layout/fonts/BelfiusNormal-Bold.woff2") format("woff2"), url("/assets/layout/fonts/BelfiusNormal-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
}
/* Used Transfonter (https://transfonter.org/) */
@font-face {
  font-family: "Open Sans";
  src: url("/assets/layout/fonts/OpenSans-Regular.woff2") format("woff2"), url("/assets/layout/fonts/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: url("/assets/layout/fonts/OpenSans-Italic.woff2") format("woff2"), url("/assets/layout/fonts/OpenSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: url("/assets/layout/fonts/OpenSans-Bold.woff2") format("woff2"), url("/assets/layout/fonts/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
}
/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/layout/fonts/roboto-v15-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/layout/fonts/roboto-v15-latin-300.eot?#iefix") format("embedded-opentype"), url("/assets/layout/fonts/roboto-v15-latin-300.woff2") format("woff2"), url("/assets/layout/fonts/roboto-v15-latin-300.woff") format("woff"), url("/assets/layout/fonts/roboto-v15-latin-300.ttf") format("truetype"), url("/assets/layout/fonts/roboto-v15-latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/layout/fonts/roboto-v15-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("/assets/layout/fonts/roboto-v15-latin-regular.eot#iefix") format("embedded-opentype"), url("/assets/layout/fonts/roboto-v15-latin-regular.woff2") format("woff2"), url("/assets/layout/fonts/roboto-v15-latin-regular.woff") format("woff"), url("/assets/layout/fonts/roboto-v15-latin-regular.ttf") format("truetype"), url("/assets/layout/fonts/roboto-v15-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/layout/fonts/roboto-v15-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("/assets/layout/fonts/roboto-v15-latin-700.eot#iefix") format("embedded-opentype"), url("/assets/layout/fonts/roboto-v15-latin-700.woff2") format("woff2"), url("/assets/layout/fonts/roboto-v15-latin-700.woff") format("woff"), url("/assets/layout/fonts/roboto-v15-latin-700.ttf") format("truetype"), url("/assets/layout/fonts/roboto-v15-latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #fff8e1;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90a4ae;
}

hr {
  border-top: solid #bdbdbd;
  border-width: 1px 0 0 0;
  margin: 1rem 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5;
}
p:last-child {
  margin-bottom: 0;
}

/* Utils */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

*[hidden] {
  display: none;
}

.card {
  background: #ffffff;
  padding: 1.25rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.card:last-child {
  margin-bottom: 0;
}
.card .card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.card .card-subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  margin: -1rem 0 1rem 0;
}

.p-toast.p-toast-top-right, .p-toast.p-toast-top-left, .p-toast.p-toast-top-center {
  top: 85px;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.splash-screen {
  width: 100%;
  min-height: 100%;
  background-color: #c30045;
  position: absolute;
}

.splash-loader-container {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
}

.splash-loader {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.splash-path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
.dashboard .overview {
  padding: 0 !important;
  min-height: 140px;
  position: relative;
  margin-bottom: 0 !important;
}
.dashboard .overview .overview-content {
  padding: 16px;
}
.dashboard .overview .overview-content .overview-title {
  font-size: 18px;
}
.dashboard .overview .overview-content .overview-badge {
  float: right;
  color: rgba(0, 0, 0, 0.6);
}
.dashboard .overview .overview-content .overview-detail {
  display: block;
  font-size: 24px;
  margin-top: 5px;
}
.dashboard .overview .overview-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.dashboard .overview .overview-footer img {
  display: block;
}
.dashboard .colorbox {
  padding: 0 !important;
  text-align: center;
  overflow: hidden;
  margin: 0 !important;
}
.dashboard .colorbox i {
  font-size: 36px;
  margin-top: 10px;
  color: #ffffff;
}
.dashboard .colorbox .colorbox-name {
  font-size: 20px;
  display: inline-block;
  width: 100%;
  margin: 4px 0 10px 0;
  color: #ffffff;
}
.dashboard .colorbox .colorbox-count {
  color: #ffffff;
  font-size: 36px;
}
.dashboard .colorbox .colorbox-count {
  font-weight: bold;
}
.dashboard .colorbox.colorbox-1 {
  background-color: #4caf50;
}
.dashboard .colorbox.colorbox-1 div:first-child {
  background-color: #2e7d32;
}
.dashboard .colorbox.colorbox-2 {
  background-color: #03a9f4;
}
.dashboard .colorbox.colorbox-2 div:first-child {
  background-color: #0277bd;
}
.dashboard .colorbox.colorbox-3 {
  background-color: #673ab7;
}
.dashboard .colorbox.colorbox-3 div:first-child {
  background-color: #4527a0;
}
.dashboard .colorbox.colorbox-4 {
  background-color: #009688;
}
.dashboard .colorbox.colorbox-4 div:first-child {
  background-color: #00695c;
}
.dashboard .task-list {
  overflow: hidden;
}
.dashboard .task-list > .p-panel {
  min-height: 340px;
}
.dashboard .task-list .p-panel-content {
  padding: 10px 0 !important;
}
.dashboard .task-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dashboard .task-list ul li {
  padding: 0.625em 0.875em;
  border-bottom: 1px solid #dbdbdb;
}
.dashboard .task-list ul .p-checkbox {
  vertical-align: middle;
  margin-right: 5px;
}
.dashboard .task-list ul .task-name {
  vertical-align: middle;
}
.dashboard .task-list ul i {
  color: rgba(0, 0, 0, 0.6);
  float: right;
  font-size: 1.25em;
}
.dashboard .contact-form {
  overflow: hidden;
}
.dashboard .contact-form .p-panel {
  min-height: 340px;
}
.dashboard .contact-form .p-button {
  margin-top: 10px;
}
.dashboard .contacts {
  overflow: hidden;
}
.dashboard .contacts > .p-panel {
  min-height: 340px;
}
.dashboard .contacts .p-panel-content {
  padding: 5px 0 10px 0 !important;
}
.dashboard .contacts ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dashboard .contacts ul li {
  border-bottom: 1px solid #d8d8d8;
}
.dashboard .contacts ul li a {
  padding: 9px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  display: block;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.dashboard .contacts ul li a .name {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
  color: rgb(0, 0, 1);
}
.dashboard .contacts ul li a .email {
  position: absolute;
  right: 10px;
  top: 30px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.dashboard .contacts ul li a:hover {
  cursor: pointer;
  background-color: #e8e8e8;
}
.dashboard .contacts ul li:last-child {
  border: 0;
}
.dashboard .activity-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dashboard .activity-list li {
  border-bottom: 1px solid #bdbdbd;
  padding: 16px 8px;
}
.dashboard .activity-list li .count {
  font-size: 20px;
  color: #ffffff;
  background-color: #007be5;
  font-weight: 700;
  padding: 0.25em 0.5em;
  display: inline-block;
  border-radius: 4px;
}
.dashboard .timeline {
  height: 100%;
  box-sizing: border-box;
}
.dashboard .timeline > .p-grid .p-col-3 {
  font-size: 14px;
  position: relative;
  border-right: 1px solid #bdbdbd;
}
.dashboard .timeline > .p-grid .p-col-3 i {
  background-color: #ffffff;
  font-size: 28px;
  position: absolute;
  top: 0;
  right: -14px;
}
.dashboard .timeline > .p-grid .p-col-9 {
  padding-left: 1.5em;
}
.dashboard .timeline > .p-grid .p-col-9 .event-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  display: block;
  padding-bottom: 20px;
}
.dashboard .timeline > .p-grid .p-col-9 .event-content img {
  width: 100%;
}
.dashboard > div > .p-panel {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.layout-rightpanel .layout-rightpanel-header {
  background: url("/assets/layout/images/dashboard/sidebar-image.jpg") no-repeat;
  background-size: cover;
  height: 118px;
  padding: 20px 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.layout-rightpanel .layout-rightpanel-header .weather-day,
.layout-rightpanel .layout-rightpanel-header .weather-date {
  color: #ffffff;
  left: 14px;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 4px;
}
.layout-rightpanel .layout-rightpanel-content {
  padding: 14px;
}
.layout-rightpanel .layout-rightpanel-content h1 {
  font-size: 18px;
  margin: 0 0 4px 0;
}
.layout-rightpanel .layout-rightpanel-content h2 {
  font-size: 16px;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
}
.layout-rightpanel .layout-rightpanel-content .weather-today {
  text-align: center;
  margin-top: 28px;
}
.layout-rightpanel .layout-rightpanel-content .weather-today .weather-today-value {
  font-size: 36px;
  vertical-align: middle;
  margin-right: 14px;
}
.layout-rightpanel .layout-rightpanel-content .weather-today img {
  vertical-align: middle;
}
.layout-rightpanel .layout-rightpanel-content .weekly-weather {
  list-style-type: none;
  margin: 28px 0 0 0;
  padding: 0;
}
.layout-rightpanel .layout-rightpanel-content .weekly-weather li {
  padding: 8px 14px;
  border-bottom: 1px solid #d8dae2;
  position: relative;
}
.layout-rightpanel .layout-rightpanel-content .weekly-weather li .weekly-weather-value {
  position: absolute;
  right: 40px;
}
.layout-rightpanel .layout-rightpanel-content .weekly-weather li img {
  width: 24px;
  position: absolute;
  right: 0px;
  top: 4px;
}

.login-body {
  padding: 1px;
  background: url("/assets/layout/images/login/login.png") top left no-repeat #f7f7f7;
  background-size: 100% auto;
  height: auto;
}

.login-panel {
  text-align: center;
  width: 350px;
  min-height: 440px;
  padding: 50px 20px;
  margin: 100px auto 0 auto;
}
.login-panel .p-grid .p-col-12 {
  padding: 25px 40px;
}
.login-panel .p-grid .p-col-12 .p-button {
  margin-bottom: 20px;
}
.login-panel .p-button:hover {
  background-color: #c30045;
}
.login-panel .p-button:focus {
  outline: 0 none;
  background-color: #f60057;
}
.login-panel .p-button.secondary:hover {
  background-color: #ff8f00;
}
.login-panel .p-button.secondary:focus {
  outline: 0 none;
  background-color: #f3626d;
}

.login-footer {
  position: absolute;
  bottom: 10px;
  font-size: 16px;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .login-body {
    background: url("/assets/layout/images/login/login2x.png") top left no-repeat #f7f7f7;
    background-size: 100% auto;
  }
}
@media (max-width: 1024px) {
  .login-panel {
    text-align: center;
    min-height: 440px;
    margin: 100px auto 0 auto;
  }
}
@media (max-width: 640px) {
  .login-panel {
    text-align: center;
    width: 300px;
    min-height: 440px;
    padding: 40px 20px;
    margin: 75px auto 0 auto;
  }
  .login-panel .p-grid .p-col-12 {
    padding: 20px 20px;
  }
  .login-panel .p-grid .p-col-12 .p-button {
    margin-top: 30px;
  }
}
.exception-body {
  background-color: #f7f7f7;
  height: auto;
}
.exception-body .exception-type {
  width: 100%;
  height: 50%;
  padding: 100px 100px 0 100px;
  box-sizing: border-box;
  text-align: center;
}
.exception-body .exception-panel {
  text-align: center;
  width: 350px;
  padding: 35px;
  margin: -10% auto 0 auto;
  z-index: 100;
}
.exception-body .exception-panel i {
  font-size: 72px;
}
.exception-body .exception-panel h1 {
  font-size: 36px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 2.5rem;
}
.exception-body .exception-panel .exception-detail {
  margin: 20px 0px 100px 0px;
  color: rgba(0, 0, 0, 0.6);
}
.exception-body .p-button {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.exception-body .p-button:hover {
  background-color: #ff8f00;
}
.exception-body.error-page .exception-type {
  background-color: #e62a10;
}
.exception-body.error-page .exception-type img {
  width: 100%;
}
.exception-body.error-page .exception-panel i {
  color: #f79a84;
}
.exception-body.notfound-page .exception-type {
  background-color: #3f51b5;
}
.exception-body.notfound-page .exception-type img {
  width: 54%;
}
.exception-body.notfound-page .exception-panel i {
  color: #9fa8da;
}
.exception-body.accessdenied-page .exception-type {
  background-color: #e91e63;
}
.exception-body.accessdenied-page .exception-type img {
  width: 50%;
}
.exception-body.accessdenied-page .exception-panel i {
  color: #f48fb1;
}

@media (max-width: 1024px) {
  .exception-body .exception-panel {
    margin-top: -50px;
  }
}
@media (max-width: 640px) {
  .exception-body .exception-panel {
    width: 250px;
    margin-top: -15px;
  }
}
.landing-wrapper .p-button {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.landing-wrapper .p-button:hover {
  background-color: #ff8f00;
}
.landing-wrapper #header {
  width: 100%;
  min-height: 400px;
  background: url("/assets/layout/images/landing/landing-header.png") top left no-repeat #f7f7f7;
  background-size: 100% auto;
}
.landing-wrapper #header .header-top {
  width: 960px;
  margin: 0 auto;
  padding: 30px 0px;
}
.landing-wrapper #header .header-top .logo {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  height: 30px;
  background: url("/assets/layout/images/logo.png") top left no-repeat;
}
.landing-wrapper #header .header-top #menu {
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
}
.landing-wrapper #header .header-top #menu li {
  float: left;
  display: block;
  margin-left: 30px;
}
.landing-wrapper #header .header-top #menu li a {
  color: #ffffff;
}
.landing-wrapper #header .header-top #menu li i {
  display: none;
}
.landing-wrapper #header .header-top #menu.lmenu-active {
  display: block;
}
.landing-wrapper #header .header-top #menu-button {
  height: 36px;
  margin-top: -2px;
  float: right;
  color: #ffffff;
  display: none;
}
.landing-wrapper #header .header-top #menu-button i {
  font-size: 36px;
}
.landing-wrapper #header .header-content {
  width: 960px;
  margin: 0 auto;
  text-align: center;
}
.landing-wrapper #header .header-content h1 {
  margin: 75px 0px 50px 0px;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
}
.landing-wrapper #features {
  width: 960px;
  margin: 0 auto;
  padding: 50px 0px;
  text-align: center;
}
.landing-wrapper #features h2 {
  font-weight: 400;
  line-height: 28px;
}
.landing-wrapper #features h3 {
  font-weight: 400;
}
.landing-wrapper #features p {
  color: rgba(0, 0, 0, 0.6);
}
.landing-wrapper #features .p-col-12 {
  padding: 2em 0.5em;
}
.landing-wrapper #features .feature-icon {
  display: inline-block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #f4f8fc;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  text-align: center;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.landing-wrapper #features .feature-icon i {
  margin-top: 30px;
  font-size: 36px;
}
.landing-wrapper #features .feature-icon:hover {
  background-color: #e91e63;
}
.landing-wrapper #features .feature-icon:hover i {
  color: #ffffff;
}
.landing-wrapper #promotion {
  background: url("/assets/layout/images/landing/promotion.png") top left no-repeat;
  background-size: 100% auto;
}
.landing-wrapper #promotion .p-lg-8 {
  padding: 150px 0 0 150px;
}
.landing-wrapper #promotion .p-lg-8 h1 {
  font-weight: 48px;
  color: #ffffff;
  font-weight: 400;
}
.landing-wrapper #promotion .p-lg-4 {
  margin: -50px 0 -50px 0;
}
.landing-wrapper #promotion .p-lg-4 .card {
  -webkit-box-shadow: 0 0 27px 4.5px rgba(13, 36, 62, 0.1);
  -moz-box-shadow: 0 0 27px 4.5px rgba(13, 36, 62, 0.1);
  box-shadow: 0 0 27px 4.5px rgba(13, 36, 62, 0.1);
  margin-bottom: 20px;
}
.landing-wrapper #promotion .p-lg-4 .card h3 {
  font-weight: 400;
  margin-top: 1.5rem;
}
.landing-wrapper #promotion .p-lg-4 .card p {
  color: rgba(0, 0, 0, 0.6);
}
.landing-wrapper #promotion .p-lg-4 .card:last-child {
  margin-bottom: 0;
}
.landing-wrapper #pricing {
  width: 960px;
  margin: 0 auto;
  padding: 50px 0px;
  text-align: center;
}
.landing-wrapper #pricing h2 {
  font-weight: 400;
}
.landing-wrapper #pricing p {
  color: rgba(0, 0, 0, 0.6);
}
.landing-wrapper #pricing .pricing-box .card {
  height: 100%;
  padding: 0;
}
.landing-wrapper #pricing .pricing-box .pricing-header {
  padding: 40px 0;
  color: #ffffff;
}
.landing-wrapper #pricing .pricing-box .pricing-header span {
  display: block;
  line-height: 48px;
}
.landing-wrapper #pricing .pricing-box .pricing-header span.name {
  font-weight: 300;
  font-size: 24px;
}
.landing-wrapper #pricing .pricing-box .pricing-header span.fee {
  font-size: 48px;
  font-weight: 700;
}
.landing-wrapper #pricing .pricing-box .pricing-header span.type {
  font-weight: 300;
  font-size: 16px;
}
.landing-wrapper #pricing .pricing-box .pricing-content ul {
  margin: 0;
  padding: 30px 20px;
  list-style-type: none;
}
.landing-wrapper #pricing .pricing-box .pricing-content ul li {
  font-size: 18px;
  text-align: left;
  padding: 10px 14px;
}
.landing-wrapper #pricing .pricing-box .pricing-content ul li i {
  margin-right: 20px;
  vertical-align: middle;
}
.landing-wrapper #pricing .pricing-box .pricing-content ul li span {
  vertical-align: middle;
}
.landing-wrapper #pricing .pricing-box.pricing-basic .pricing-header {
  background-color: #3f51b5;
}
.landing-wrapper #pricing .pricing-box.pricing-basic i {
  color: #3f51b5;
}
.landing-wrapper #pricing .pricing-box.pricing-standard .pricing-header {
  background-color: #e91e63;
}
.landing-wrapper #pricing .pricing-box.pricing-standard i {
  color: #e91e63;
}
.landing-wrapper #pricing .pricing-box.pricing-professional .pricing-header {
  background-color: #607d8b;
}
.landing-wrapper #pricing .pricing-box.pricing-professional i {
  color: #607d8b;
}
.landing-wrapper #video {
  background-color: #f7f7f7;
  min-width: 400px;
}
.landing-wrapper #video .video-content {
  width: 960px;
  margin: 0 auto;
  padding: 50px 0px;
  text-align: center;
}
.landing-wrapper #video .video-content h2 {
  font-weight: 400;
}
.landing-wrapper #video .video-content p {
  color: rgba(0, 0, 0, 0.6);
}
.landing-wrapper .footer {
  background-color: #f7f7f7;
  border-top: 1px solid #dddddd;
}
.landing-wrapper .footer .footer-content {
  width: 960px;
  margin: 0 auto;
  padding: 30px 0px 50px 0px;
}
.landing-wrapper .footer .footer-content ul {
  float: right;
  list-style-type: none;
}
.landing-wrapper .footer .footer-content ul li a {
  color: rgba(0, 0, 0, 0.6);
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.landing-wrapper .footer .footer-content ul li a:hover {
  color: rgb(0, 0, 1);
}

@media (max-width: 1024px) {
  .landing-wrapper #header {
    min-height: 200px;
    background-size: cover;
  }
  .landing-wrapper #header .header-top {
    z-index: 100;
    position: fixed;
    top: 0;
    background: #424242;
    background-size: 100% auto;
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
  .landing-wrapper #header .header-top #menu-button {
    display: inline-block;
  }
  .landing-wrapper #header .header-top #menu {
    z-index: 100;
    position: fixed;
    top: 86px;
    right: 30px;
    float: none;
    display: none;
    margin: 0;
    padding: 0;
    width: 225px;
    list-style: none;
    background-color: #ffffff;
    -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  .landing-wrapper #header .header-top #menu li {
    float: none;
    margin-left: 0;
  }
  .landing-wrapper #header .header-top #menu li a {
    font-size: 16px;
    display: block;
    padding: 10px 16px;
    color: rgb(0, 0, 1);
    width: 100%;
    box-sizing: border-box;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    overflow: hidden;
  }
  .landing-wrapper #header .header-top #menu li a i {
    color: #757575;
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    font-size: 24px;
  }
  .landing-wrapper #header .header-top #menu li a:hover {
    background-color: #e8e8e8;
  }
  .landing-wrapper #header .header-top #menu li a span {
    display: inline-block;
    vertical-align: middle;
  }
  .landing-wrapper #header .header-content {
    width: 100%;
    padding: 100px 30px 60px 30px;
    box-sizing: border-box;
  }
  .landing-wrapper #header .header-content h1 {
    margin: 75px 0px 50px 0px;
    font-weight: 400;
  }
  .landing-wrapper #features,
.landing-wrapper #promotion,
.landing-wrapper #pricing,
.landing-wrapper #video,
.landing-wrapper .footer .footer-content {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    box-sizing: border-box;
  }
  .landing-wrapper #promotion .p-lg-8 {
    padding: 100px 0 30px;
    text-align: center;
  }
  .landing-wrapper #promotion .p-lg-8 h1 {
    margin-top: -30px;
    font-weight: 48px;
    color: #ffffff;
    font-weight: 400;
  }
  .landing-wrapper #video .video-content {
    width: 100%;
  }
  .landing-wrapper #video .video-content .video-container iframe {
    width: 350px;
    height: 220px;
  }
  .landing-wrapper .footer .footer-content {
    text-align: center;
  }
  .landing-wrapper .footer .footer-content ul {
    float: none;
    margin: 0;
    padding: 0;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .landing-wrapper .header .header-top .logo {
    background: url("/assets/layout/images/logo2x.png") top left no-repeat;
    background-size: 200px 30px;
  }
}
.help-wrapper .card {
  background-color: #f3f5f7;
}
.help-wrapper .card.help-wrapper-card {
  padding: 0;
}
.help-wrapper .card.help-wrapper-card .help-header {
  position: relative;
}
.help-wrapper .card.help-wrapper-card .help-header h1 {
  color: #ffffff;
  font-size: 28px;
  position: absolute;
  top: 40%;
  left: 40px;
  letter-spacing: 0.25px;
}
.help-wrapper .card.help-wrapper-card .help-header .search {
  bottom: -20px;
  position: absolute;
  height: 50px;
  background-color: #fafafa;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  left: 40px;
  right: 40px;
}
.help-wrapper .card.help-wrapper-card .help-header .search span {
  width: 100%;
}
.help-wrapper .card.help-wrapper-card .help-header .search span input {
  border: none;
  position: relative;
  width: 100%;
  padding: 10px 40px;
  height: 50px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.help-wrapper .card.help-wrapper-card .help-header .search i {
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.help-wrapper .card.help-wrapper-card .help-content {
  padding: 20px 0;
}
.help-wrapper .card.help-wrapper-card .help-content .card {
  margin: 20px 40px;
  background-color: #fafafa;
  padding: 5px 20px;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion {
  background-color: #f3f5f7;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-tab {
  box-shadow: none;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-header > a {
  border: none;
  background-color: #fafafa;
  color: #212121;
  position: relative;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-header > a .accordion-title {
  padding-left: 45px;
  color: #212121;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-header > a .accordion-title h4 {
  margin-bottom: 0;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-header > a i {
  color: #f03241;
  position: absolute;
  bottom: 28px;
  left: 15px;
  z-index: 1;
  font-size: 40px;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-header > a .p-accordion-toggle-icon {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  color: #212121;
  right: 30px;
  left: auto;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-content {
  border: none;
  box-shadow: none;
  background-color: #fafafa;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-content .questions .sub-accordion .p-accordion-tab {
  box-shadow: none;
  margin-bottom: 10px;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-content .questions .sub-accordion .p-accordion-header > a {
  background-color: #eaeaea;
  color: #212121;
  padding: 20px;
  border-radius: 2px;
  border: solid 1px #e0e0e0;
  font-size: 16px;
  letter-spacing: 0.12px;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-content .questions .sub-accordion .p-accordion-header > a .p-accordion-toggle-icon {
  color: #212121;
  right: 15px;
  left: auto;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-content .questions .sub-accordion .p-accordion-header > a .p-accordion-header-text {
  color: #212121;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-content .questions .sub-accordion .p-accordion-header > a:hover {
  background-color: #c30045;
  border-color: #c30045;
  color: #ffffff;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-content .questions .sub-accordion .p-accordion-header > a:hover .p-accordion-toggle-icon {
  color: #ffffff;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-content .questions .sub-accordion .p-accordion-header > a:hover .p-accordion-header-text {
  color: #ffffff;
}
.help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-content .questions .sub-accordion .p-accordion-content {
  padding-bottom: 24px;
  line-height: 1.5;
}

@media (max-width: 640px) {
  .help-wrapper .card.help-wrapper-card {
    padding: 0;
  }
  .help-wrapper .card.help-wrapper-card .help-header {
    line-height: 1.5;
  }
  .help-wrapper .card.help-wrapper-card .help-header img {
    height: 130px;
  }
  .help-wrapper .card.help-wrapper-card .help-header h1 {
    top: 0;
  }
  .help-wrapper .card.help-wrapper-card .help-header .search {
    left: 10px;
    right: 10px;
  }
  .help-wrapper .card.help-wrapper-card .help-content .card {
    margin: 10px;
    padding: 0px 5px;
  }
  .help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-header > a .p-accordion-toggle-icon {
    right: 5px;
  }
  .help-wrapper .card.help-wrapper-card .help-content .card .main-accordion .p-accordion-content .questions .sub-accordion .p-accordion-header > a .p-accordion-toggle-icon {
    right: 2px;
  }
}
.invoice-wrapper .invoice-header {
  margin-bottom: 30px;
}
.invoice-wrapper .invoice-header .title {
  font-size: 28px;
  font-weight: 900;
  color: rgb(0, 0, 1);
}
.invoice-wrapper .invoice-header .logo-adress {
  text-align: right;
}
.invoice-wrapper .card.invoice-table {
  padding: 0;
  margin-bottom: 42px;
  width: 100%;
}
.invoice-wrapper .card.invoice-table h2,
.invoice-wrapper .card.invoice-table p {
  margin: 0;
}
.invoice-wrapper .card.invoice-table .table-header {
  padding: 3px 5px;
  border-radius: 2px;
  background-color: #e0e0e0;
  text-align: right;
}
.invoice-wrapper .card.invoice-table .table-header h2 {
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
}
.invoice-wrapper .card.invoice-table .table-content-row {
  padding: 3px 5px;
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  text-align: right;
}
.invoice-wrapper .card.invoice-table .table-content-row h2 {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}
.invoice-wrapper .card.invoice-table .row-title {
  text-align: left;
}
.invoice-wrapper .card.invoice-table .total {
  color: #f03241;
}
.invoice-wrapper .card.invoice-table.billto-table .table-header {
  text-align: left;
}
.invoice-wrapper .card.invoice-table.billto-table .table-content-row {
  text-align: left;
}
.invoice-wrapper .card.invoice-table.bank-table {
  margin-right: 25px;
}
.invoice-wrapper .table-g-6 {
  padding: 0;
}
.invoice-wrapper .p-nogutter > .p-col,
.invoice-wrapper .p-nogutter > [class*=p-col-] {
  padding: 0.5em;
}

@media (max-width: 1024px) {
  .invoice-wrapper .card.invoice-table.bank-table {
    margin-right: 0;
  }
}
@media (max-width: 640px) {
  .invoice-wrapper .logo-adress img {
    width: 135px;
  }
  .invoice-wrapper .invoice-table .table-content-row {
    font-size: 12px;
  }
}
@media print {
  body * {
    visibility: hidden;
  }
  #invoice-content * {
    visibility: visible;
  }
  #invoice-content {
    position: absolute;
    left: 0;
    top: 0;
  }
  #invoice-content .card {
    box-shadow: none;
  }
  #invoice-content .card.invoice-table {
    margin-bottom: 10px;
    background-color: transparent;
  }
}
.wizard-body {
  height: 100vh;
  background: url("/assets/layout/images/extensions/background@2x.jpg") center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.wizard-body .wizard-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wizard-body .wizard-wrapper .wizard-topbar {
  background-color: #3949ab;
  z-index: 1000;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  height: 75px;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 75px;
  flex-grow: 0;
  flex-shrink: 0;
}
.wizard-body .wizard-wrapper .wizard-topbar .logo {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  height: 30px;
  background: url("/assets/layout/images/logo.png") top left no-repeat;
}
.wizard-body .wizard-wrapper .wizard-topbar .profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.wizard-body .wizard-wrapper .wizard-topbar .profile .profile-text {
  margin-right: 15px;
  text-align: right;
}
.wizard-body .wizard-wrapper .wizard-topbar .profile .profile-text h1 {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
}
.wizard-body .wizard-wrapper .wizard-topbar .profile .profile-text p {
  font-size: 16px;
  opacity: 0.6;
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
}
.wizard-body .wizard-wrapper .wizard-topbar .profile .profile-image {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
}
.wizard-body .wizard-wrapper .wizard-content {
  height: calc(100% - 75px);
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card {
  background-color: #fafafa;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  height: 550px;
  width: 54.33%;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header {
  width: 100%;
  background-color: #3f51b5;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab {
  background-color: #3f51b5;
  text-align: center;
  cursor: pointer;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab i {
  width: 20px;
  opacity: 0.38;
  color: #ffffff;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab .title {
  color: #ffffff;
  opacity: 0.38;
  font-size: 16px;
  line-height: 1.5em;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab .icon {
  line-height: 1.5em;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab.selected-tab {
  transition-duration: 0.6s;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab.selected-tab i {
  opacity: 1;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab.selected-tab .title {
  opacity: 1;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 33.3333%;
  transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  background-color: #ffffff;
  visibility: visible;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab-bar.tab-bar-register {
  width: 33.3333%;
  left: 0;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab-bar.tab-bar-tier {
  width: 33.3333%;
  left: 33.3333%;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-header .tab-bar.tab-bar-payment {
  width: 33.3333%;
  left: 66.6667%;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content {
  padding: 30px;
  display: none;
  overflow: auto;
  height: 100%;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content > .p-grid {
  height: 100%;
  width: 100%;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content h1 {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 2px;
  margin: 0;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.active-content {
  display: flex;
  flex-grow: 1;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.register .forms .p-inputgroup {
  margin-top: 25px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.register .forms .p-inputgroup input {
  width: 100%;
  padding: 15px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.register .forms .p-dropdown {
  margin-top: 25px;
  width: 100%;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.register .forms .p-dropdown .p-dropdown-trigger {
  right: 10px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.register .forms .calendar {
  margin-top: 14px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.register .forms .calendar .p-calendar {
  width: 100%;
  position: relative;
  padding: 1px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.register .forms .calendar .p-calendar input {
  width: 100%;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.register .continue-button.p-button {
  width: 100%;
  margin-top: 25px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card {
  min-height: 400px;
  padding: 0;
  position: relative;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card .card-header {
  color: #ffffff;
  font-size: 18px;
  padding: 15px 10px;
  background-color: #3f51b5;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card .card-header h1 {
  color: #ffffff;
  font-size: 24px;
  display: inline;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card .card-content {
  font-size: 14px;
  padding: 10px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card .card-content i {
  color: #3f51b5;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card .card-content .card-row {
  height: 40px;
  width: 100%;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card .card-content .tier-button-wrapper {
  position: absolute;
  bottom: 15px;
  right: 10px;
  left: 0px;
  width: auto;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card .card-content .tier-button-wrapper .tier-button.p-button {
  width: 100%;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card.pro .card-header {
  background-color: #e91e63;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card.pro .card-content i {
  color: #e91e63;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card.pro .card-content .tier-button.p-button {
  background-color: #e91e63;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card.pro-plus .card-header {
  background-color: #607d8b;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card.pro-plus .card-content i {
  color: #607d8b;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.tier .card.pro-plus .card-content .tier-button.p-button {
  background-color: #607d8b;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment {
  padding: 0;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .payment-info {
  padding: 70px 35px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .payment-info .p-float-label input {
  width: 100%;
  padding-bottom: 15px;
  background-color: transparent;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .payment-info .p-checkbox-label {
  margin-left: 0.5em;
  font-size: 14px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .payment-info #customPanel {
  width: 100%;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .payment-info .check-info {
  margin-top: 10px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .order-info {
  padding: 15px;
  background-color: #e0e0e0;
  border-left: solid 1px #bdbdbd;
  font-size: 14px;
  color: #757575;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .order-info .order-basic,
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .order-info .order-pro,
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .order-info .order-pro-plus,
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .order-info .order-default {
  display: none;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .order-info .selected-order {
  display: block;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .order-info h1 {
  margin-top: 15px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .order-info .price {
  font-weight: 700;
  text-align: right;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .order-info .total {
  border-top: 1px solid #bdbdbd;
  padding: 15px 0px;
  margin-top: 30px;
}
.wizard-body .wizard-wrapper .wizard-content .wizard-card .wizard-card-content.payment .order-info .buy-button.p-button {
  width: 100%;
  margin: 68px 0;
}

@media (max-width: 1024px) {
  .wizard-body .wizard-wrapper .wizard-content .wizard-card {
    width: 90%;
  }
}
@media (max-width: 640px) {
  .wizard-body .wizard-wrapper .wizard-topbar {
    height: 150px;
    padding: 0 5%;
    flex-direction: column;
    justify-content: space-around;
    flex-basis: 150px;
  }
  .wizard-body .wizard-wrapper .wizard-topbar .logo {
    align-self: flex-start;
  }
  .wizard-body .wizard-wrapper .wizard-topbar .profile {
    align-self: flex-end;
  }
  .wizard-body .wizard-wrapper .wizard-content {
    height: calc(100% - 150px);
  }
}
html {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: rgb(0, 0, 1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  background-color: #f7f7f7;
  min-height: 100%;
}
body a {
  text-decoration: none;
  color: #c30045;
}

.layout-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #424242;
  top: 0;
  left: 0;
  z-index: 999999997;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

div.tooltip {
  position: absolute;
  text-align: center;
  top: 130px;
  left: 230px;
  width: 260px;
  height: 360px;
  padding: 2px;
  font: 12px sans-serif;
  background: black;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  opacity: 0.8;
  z-index: 999999997;
}

.layout-container .topbar {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 75px;
  background-color: #c30045;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.layout-container .topbar .logo {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  height: 30px;
  background: url("/assets/layout/images/logo2x.png") top left no-repeat;
  background-size: 200px 30px;
}
.layout-container .topbar .topbar-left {
  box-sizing: border-box;
  padding: 20px;
  height: 75px;
  width: 250px;
  background-color: #c30045;
  float: left;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
}
.layout-container .topbar .topbar-right {
  padding: 15px 15px 15px 0;
  position: relative;
  width: calc(100% - 250px);
  float: right;
}
.layout-container .topbar .topbar-right #menu-button {
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  height: 36px;
  width: 36px;
  margin-right: 10px;
  position: relative;
  left: -16px;
  top: 3px;
  background-color: #f03241;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
}
.layout-container .topbar .topbar-right #menu-button:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.layout-container .topbar .topbar-right #menu-button i {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 30px;
  margin-top: 3px;
}
.layout-container .topbar .topbar-right #topbar-menu-button,
.layout-container .topbar .topbar-right #rightpanel-menu-button {
  display: none;
  color: #ffffff;
  vertical-align: middle;
  height: 36px;
  width: 36px;
  line-height: 36px;
  margin-top: 7px;
  float: right;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.layout-container .topbar .topbar-right #topbar-menu-button i,
.layout-container .topbar .topbar-right #rightpanel-menu-button i {
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  font-size: 24px;
  vertical-align: text-bottom;
}
.layout-container .topbar .topbar-right #rightpanel-menu-button {
  display: block;
}
.layout-container .topbar .topbar-right #rightpanel-menu-button:hover {
  color: #e8e8e8;
}
.layout-container .topbar .topbar-right .topbar-items .search-item {
  margin-top: 0;
}
.layout-container .topbar .topbar-right .topbar-items .search-item input {
  padding: 0.5rem 1rem 0.5rem 3rem;
}
.layout-container .topbar .topbar-right .topbar-items .search-item i {
  color: rgb(0, 0, 1);
  font-size: 1em;
}
.layout-container .layout-menu {
  overflow: auto;
  position: fixed;
  width: 250px;
  z-index: 99;
  top: 75px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -moz-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  -webkit-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
}
.layout-container .layout-menu .profile {
  box-sizing: border-box;
  padding-top: 2em;
  width: 250px;
  height: 145px;
  text-align: center;
  background: url("/assets/layout/images/profile-bg.png") top left no-repeat;
  background-size: 250px 145px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
}
.layout-container .layout-menu .profile .profile-image {
  width: 60px;
  height: 60px;
  margin: 0 auto 5px auto;
  display: block;
}
.layout-container .layout-menu .profile .profile-name {
  display: inline-block;
  color: rgb(0, 0, 1);
  vertical-align: middle;
  font-size: 1em;
}
.layout-container .layout-menu .profile i {
  color: rgb(0, 0, 1);
  vertical-align: middle;
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
}
.layout-container .layout-menu .profile.profile-expanded i {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.layout-container .layout-menu .profile-menu {
  border-bottom: 1px solid #d6d5d5;
  overflow: hidden;
}
.layout-container .layout-menu .profile-menu li:first-child {
  margin-top: 1em;
}
.layout-container .layout-menu .profile-menu li:last-child {
  margin-bottom: 1em;
}
.layout-container .layout-menu.layout-menu-dark {
  background-color: #424242;
}
.layout-container .layout-menu.layout-menu-dark .profile {
  background-image: url("/assets/layout/images/profile-bg-dark.png");
}
.layout-container .layout-menu.layout-menu-dark .profile .profile-name {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark .profile i {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark .profile-menu {
  border-bottom: 1px solid #545454;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a i {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a:hover {
  background-color: #676767;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink i {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink:hover {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink:hover > i {
  color: #ffffff;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem > a {
  color: #ffffff;
  background-color: #f03241;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem > a.active-menuitem-routerlink {
  color: #ffffff;
  background-color: #f03241;
}
.layout-container .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem > a.active-menuitem-routerlink i {
  color: rgb(0, 0, 1);
}
.layout-container .layout-menu .menuitem-badge {
  position: absolute;
  right: 2.5em;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: #e91e63;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  border-radius: 50%;
}
.layout-container .layout-menu .layout-menu-tooltip {
  display: none;
  padding: 0 5px;
  position: absolute;
  left: 76px;
  top: 6px;
  z-index: 101;
  line-height: 1;
}
.layout-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-text {
  padding: 6px 8px;
  font-weight: 700;
  background-color: #353535;
  color: #ffffff;
  min-width: 75px;
  white-space: nowrap;
  text-align: center;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.layout-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #353535;
}
.layout-container.menu-layout-overlay .layout-menu {
  margin-left: -250px;
}
.layout-container.menu-layout-overlay .layout-main {
  margin-left: 0px;
}
.layout-container.menu-layout-overlay.layout-menu-overlay-active .layout-menu {
  z-index: 999999999;
  margin-left: 0px;
}
.layout-container.menu-layout-overlay.layout-menu-overlay-active .layout-mask {
  display: block;
}
.layout-container.menu-layout-overlay.layout-menu-overlay-active .topbar .topbar-right #menu-button i {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.layout-container.menu-layout-overlay .topbar {
  z-index: 999999998;
}
.layout-container.menu-layout-overlay .topbar .topbar-right #menu-button i {
  font-size: 36px !important;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.layout-container .layout-main {
  padding: 75px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  -moz-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  -webkit-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
}
.layout-container .layout-main .layout-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 23px;
}
.layout-container .layout-mask {
  display: none;
}
.layout-container .layout-breadcrumb {
  background-color: #ffffff;
  box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.14);
  min-height: 42px;
}
.layout-container .layout-breadcrumb:before, .layout-container .layout-breadcrumb:after {
  content: "";
  display: table;
}
.layout-container .layout-breadcrumb:after {
  clear: both;
}
.layout-container .layout-breadcrumb ul {
  margin: 10px 0 0 0;
  padding: 0 0 0 10px;
  list-style: none;
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
}
.layout-container .layout-breadcrumb ul li {
  display: inline-block;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.6);
}
.layout-container .layout-breadcrumb ul li:first-child(even) {
  color: #c30045;
}
.layout-container .layout-breadcrumb ul li a {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1em;
}
.layout-container .layout-breadcrumb ul li a i {
  font-size: 1.25em;
}
.layout-container .layout-breadcrumb .layout-breadcrumb-options {
  float: right;
  padding: 0px 20px 0 0;
  height: 100%;
}
.layout-container .layout-breadcrumb .layout-breadcrumb-options a {
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.layout-container .layout-breadcrumb .layout-breadcrumb-options a:hover {
  background-color: #e8e8e8;
}
.layout-container .layout-breadcrumb .layout-breadcrumb-options a i {
  font-size: 1.25em;
  line-height: inherit;
}
.layout-container .ultima-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.layout-container .ultima-menu.ultima-main-menu {
  margin-top: 16px;
  padding-bottom: 120px;
}
.layout-container .ultima-menu li a {
  font-size: 1em;
  display: block;
  padding: 0.5em 1em;
  color: rgb(0, 0, 1);
  width: 100%;
  box-sizing: border-box;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}
.layout-container .ultima-menu li a i {
  color: #757575;
}
.layout-container .ultima-menu li a i:first-child {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  font-size: 1.25em;
}
.layout-container .ultima-menu li a i.submenu-icon {
  float: right;
  font-size: 18px;
  margin-right: -0.15em;
  transition: transform 0.2s;
}
.layout-container .ultima-menu li a i:last-child {
  float: right;
  font-size: 18px;
  margin-top: 0.15em;
  margin-right: -0.15em;
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
}
.layout-container .ultima-menu li a:hover {
  background-color: #e8e8e8;
}
.layout-container .ultima-menu li a span {
  display: inline-block;
  vertical-align: middle;
}
.layout-container .ultima-menu li a.active-menuitem-routerlink {
  color: #ffffff;
}
.layout-container .ultima-menu li a.active-menuitem-routerlink > i {
  color: #ffffff;
}
.layout-container .ultima-menu li a.active-menuitem-routerlink:hover {
  color: rgb(0, 0, 1);
}
.layout-container .ultima-menu li a.active-menuitem-routerlink:hover > i {
  color: #757575;
}
.layout-container .ultima-menu li.active-menuitem > a {
  color: #c30045;
  background-color: #e8e8e8;
}
.layout-container .ultima-menu li.active-menuitem > a i {
  color: #c30045;
}
.layout-container .ultima-menu li.active-menuitem > a i.submenu-icon {
  transform: rotate(-180deg);
}
.layout-container .ultima-menu li ul {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}
.layout-container .ultima-menu li ul li a {
  padding: 0.5em 2.5em 0.5em 2em;
}
.layout-container .ultima-menu li ul li a > span {
  font-size: 13px;
}
.layout-container .ultima-menu li ul li a i:first-child {
  font-size: 1em;
}
.layout-container .ultima-menu li ul li ul li a {
  padding-left: 3em;
}
.layout-container .ultima-menu li ul li ul ul li a {
  padding-left: 4em;
}
.layout-container .ultima-menu li ul li ul ul ul li a {
  padding-left: 5em;
}
.layout-container .ultima-menu li ul li ul ul ul ul li a {
  padding-left: 6em;
}
.layout-container .ultima-menu li.red-badge > a .menuitem-badge {
  background-color: #f44336;
  color: #ffffff;
}
.layout-container .ultima-menu li.purple-badge > a .menuitem-badge {
  background-color: #4527a0;
  color: #ffffff;
}
.layout-container .ultima-menu li.teal-badge > a .menuitem-badge {
  background-color: #00695c;
  color: #ffffff;
}
.layout-container .footer .card {
  margin-bottom: 0;
}
.layout-container .footer .footer-text-left {
  float: left;
}
.layout-container .footer .footer-text-right {
  color: rgba(0, 0, 0, 0.6);
  float: right;
}
.layout-container .footer .footer-text-right span {
  vertical-align: middle;
  display: inline-block;
}
.layout-container .layout-rightpanel {
  position: fixed;
  top: 75px;
  height: 100%;
  right: -240px;
  width: 240px;
  z-index: 100;
  overflow: auto;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: right 0.2s;
  -o-transition: right 0.2s;
  -webkit-transition: right 0.2s;
  transition: right 0.2s;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
}
.layout-container .layout-rightpanel.layout-rightpanel-active {
  right: 0;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-container .layout-rightpanel .layout-rightpanel-content {
  padding: 14px;
  padding-bottom: 120px;
}

@media (min-width: 1025px) {
  .layout-container .topbar-items {
    float: right;
    margin: 0;
    padding: 5px 0 0 0;
    list-style-type: none;
  }
  .layout-container .topbar-items > li {
    float: right;
    position: relative;
    margin-left: 1em;
    margin-top: 4px;
  }
  .layout-container .topbar-items > li > a {
    position: relative;
    display: block;
  }
  .layout-container .topbar-items > li > a .topbar-item-name {
    display: none;
  }
  .layout-container .topbar-items > li > a .topbar-badge {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: #f03241;
    color: #ffffff;
    border-radius: 50%;
    padding: 2px 4px;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  .layout-container .topbar-items > li .topbar-icon {
    font-size: 24px;
    color: #ffffff;
    transition: color 0.2s;
  }
  .layout-container .topbar-items > li .topbar-icon:hover {
    color: #e8e8e8;
  }
  .layout-container .topbar-items > li.profile-item {
    margin-top: 0;
  }
  .layout-container .topbar-items > li.profile-item .profile-image {
    width: 36px;
    height: 36px;
  }
  .layout-container .topbar-items > li > ul {
    position: absolute;
    top: 46px;
    right: 5px;
    display: none;
    width: 250px;
    background-color: #ffffff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    animation-duration: 0.2s;
  }
  .layout-container .topbar-items > li.active-top-menu > ul {
    display: block;
  }
  .layout-container .topbar-items > li .topbar-message img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
  }
  .layout-container.menu-layout-static .layout-menu {
    margin-left: 0;
  }
  .layout-container.menu-layout-static .layout-main {
    margin-left: 250px;
  }
  .layout-container.menu-layout-static.layout-menu-static-inactive .topbar .topbar-right #menu-button i {
    margin-left: 3px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .layout-container.menu-layout-static.layout-menu-static-inactive .layout-menu {
    margin-left: -250px;
  }
  .layout-container.menu-layout-static.layout-menu-static-inactive .layout-main {
    margin-left: 0px;
  }
  .layout-container.menu-layout-static .layout-mask {
    display: none;
  }
  .layout-container.menu-layout-horizontal .topbar {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-container.menu-layout-horizontal .topbar .topbar-left {
    background-color: #c30045;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-container.menu-layout-horizontal .topbar .topbar-right #menu-button {
    display: none;
  }
  .layout-container.menu-layout-horizontal .layout-menu {
    overflow: visible;
    position: fixed;
    width: 100%;
    top: 75px;
    height: auto;
    background-color: #c30045;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu {
    width: 100%;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu.ultima-main-menu {
    margin-top: 0;
    padding-bottom: 0;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li {
    float: left;
    position: relative;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a {
    padding: 0.5em 1em;
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a:hover {
    background-color: #e8e8e8;
    color: rgb(0, 0, 1);
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a:hover i {
    color: rgb(0, 0, 1);
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink > i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink:hover {
    color: rgb(0, 0, 1);
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > a.active-menuitem-routerlink:hover i {
    color: rgb(0, 0, 1);
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul {
    position: absolute;
    top: 32px;
    left: 0px;
    width: 250px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li a {
    padding: 10px 16px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul {
    position: static;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul li a {
    padding-left: 32px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul li a {
    padding-left: 48px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul ul li a {
    padding-left: 64px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul ul ul li a {
    padding-left: 80px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li ul ul ul ul ul li a {
    padding-left: 96px;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li.active-menuitem > a {
    color: #c30045;
    background-color: #e8e8e8;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li > ul li.active-menuitem > ul {
    display: block;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li.active-menuitem > a {
    color: #ffffff;
    background-color: #f03241;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li.active-menuitem > a i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu ul.ultima-menu > li.active-menuitem > ul {
    display: block;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark {
    background-color: #424242;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a:hover {
    background-color: #676767;
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a:hover i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink:hover {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li a.active-menuitem-routerlink:hover i {
    color: #ffffff;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li.active-menuitem > a {
    color: #ffffff;
    background-color: #f03241;
  }
  .layout-container.menu-layout-horizontal .layout-menu.layout-menu-dark ul.ultima-menu li ul {
    background-color: #424242;
  }
  .layout-container.menu-layout-horizontal .layout-menu .menuitem-badge {
    left: 1.75em;
    top: 0.15em;
  }
  .layout-container.menu-layout-horizontal .layout-menu .active-menuitem .menuitem-badge {
    background-color: #ffffff;
    color: #212121;
  }
  .layout-container.menu-layout-horizontal .layout-main {
    padding-top: 106px;
    margin-left: 0px;
  }
  .layout-container.menu-layout-horizontal .layout-mask {
    display: none;
  }
  .layout-container.menu-layout-slim .topbar {
    left: 75px;
    width: calc(100% - 75px);
  }
  .layout-container.menu-layout-slim .topbar .topbar-left {
    background: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .layout-container.menu-layout-slim .topbar .topbar-right #menu-button {
    display: none;
  }
  .layout-container.menu-layout-slim .layout-menu {
    width: 75px;
    overflow: visible;
    z-index: 100;
    top: 0;
  }
  .layout-container.menu-layout-slim .layout-menu .profile {
    width: 100%;
    height: 74px;
    padding-top: 15px;
  }
  .layout-container.menu-layout-slim .layout-menu .profile > a .profile-image {
    width: 45px;
    height: 45px;
  }
  .layout-container.menu-layout-slim .layout-menu .profile > a .profile-name,
.layout-container.menu-layout-slim .layout-menu .profile > a i {
    display: none;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu {
    padding: 0;
    width: 100%;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li {
    position: relative;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > a {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > a i:first-child {
    font-size: 1.5em;
    margin-right: 0;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > a span,
.layout-container.menu-layout-slim .layout-menu .ultima-menu > li > a .submenu-icon {
    display: none;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > a:hover + .layout-menu-tooltip {
    display: block;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul {
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 75px;
    min-width: 200px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li a {
    padding: 0.5em 1em 0.5em 2em;
    padding-left: 16px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li ul li a {
    padding-left: 32px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li ul ul li a {
    padding-left: 48px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li ul ul ul li a {
    padding-left: 64px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li ul ul ul ul li a {
    padding: 80px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li > ul li ul ul ul ul ul li a {
    padding: 96px;
  }
  .layout-container.menu-layout-slim .layout-menu .ultima-menu > li.active-menuitem > a:hover + .layout-menu-tooltip {
    display: none;
  }
  .layout-container.menu-layout-slim .layout-menu.layout-menu-dark .ultima-menu > li > ul {
    background-color: #424242;
  }
  .layout-container.menu-layout-slim .layout-main {
    margin-left: 75px;
  }
  .layout-container.menu-layout-slim .layout-footer {
    margin-left: 75px;
  }
}
@media (max-width: 1024px) {
  .layout-container.menu-layout-static .topbar .topbar-right #menu-button i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .layout-container.menu-layout-static .layout-menu {
    margin-left: -265px;
  }
  .layout-container.menu-layout-static .layout-main {
    margin-left: 0px;
  }
  .layout-container.menu-layout-static.layout-menu-static-active .layout-menu {
    margin-left: 0;
    z-index: 999999999;
  }
  .layout-container.menu-layout-static.layout-menu-static-active .topbar {
    z-index: 999999998;
  }
  .layout-container.menu-layout-static.layout-menu-static-active .topbar .topbar-right #menu-button i {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  .layout-container.menu-layout-static.layout-menu-static-active .layout-main {
    margin-left: 0;
  }
  .layout-container.menu-layout-static.layout-menu-static-active .layout-mask {
    display: block;
  }
  .layout-container .topbar .topbar-right #topbar-menu-button {
    display: block;
  }
  .layout-container .topbar .topbar-right .topbar-items {
    position: absolute;
    top: 75px;
    right: 15px;
    width: 275px;
    display: none;
    background-color: #ffffff;
    -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    animation-duration: 0.2s;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .layout-container .topbar .topbar-right .topbar-items > li > a {
    width: 100%;
    display: block;
    box-sizing: border-box;
    font-size: 16px;
    padding: 16px 16px;
    color: rgb(0, 0, 1);
    position: relative;
  }
  .layout-container .topbar .topbar-right .topbar-items > li > a i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    font-size: 24px;
  }
  .layout-container .topbar .topbar-right .topbar-items > li > a:hover {
    background-color: #e8e8e8;
  }
  .layout-container .topbar .topbar-right .topbar-items > li > a .topbar-item-name {
    display: inline-block;
    vertical-align: middle;
  }
  .layout-container .topbar .topbar-right .topbar-items > li > a .topbar-badge {
    position: absolute;
    left: 30px;
    top: 10px;
    background-color: #f03241;
    color: #ffffff;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 2px 4px;
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
  .layout-container .topbar .topbar-right .topbar-items > li > ul {
    display: none;
  }
  .layout-container .topbar .topbar-right .topbar-items > li > ul li a span,
.layout-container .topbar .topbar-right .topbar-items > li > ul li a img,
.layout-container .topbar .topbar-right .topbar-items > li > ul li a i {
    display: inline-block;
    vertical-align: middle;
  }
  .layout-container .topbar .topbar-right .topbar-items > li.active-top-menu > a {
    color: #c30045;
  }
  .layout-container .topbar .topbar-right .topbar-items > li.active-top-menu > ul {
    display: block;
  }
  .layout-container .topbar .topbar-right .topbar-items > li.active-top-menu > ul li a {
    padding-left: 32px;
  }
  .layout-container .topbar .topbar-right .topbar-items > li.search-item {
    text-align: center;
    width: 100%;
    display: block;
    box-sizing: border-box;
    font-size: 16px;
    padding: 16px 16px;
    position: relative;
  }
  .layout-container .topbar .topbar-right .topbar-items > li.profile-item .profile-image {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    background: url("/assets/layout/images/avatar.png") top left no-repeat;
    background-size: 24px 24px;
    margin-right: 14px;
  }
  .layout-container .topbar .topbar-right .topbar-items > li.profile-item span {
    vertical-align: middle;
    display: inline-block;
  }
  .layout-container .topbar .topbar-right .topbar-items.topbar-items-visible {
    display: block;
  }
}
@media (max-width: 385px) {
  .layout-container .topbar .topbar-right #topbar-menu-button {
    right: 10px;
    margin-right: 25px;
    position: absolute;
    top: 15px;
  }
  .layout-container .topbar .topbar-right #rightpanel-menu-button {
    position: absolute;
    right: 5px;
    top: 15px;
    margin-left: 35px;
  }
}
.layout-config {
  position: fixed;
  top: 75px;
  padding: 0;
  right: 0;
  display: block;
  width: 16em;
  z-index: 998;
  height: calc(100% - 50px);
  transform: translate3d(16em, 0px, 0px);
  transition: transform 0.2s;
  backface-visibility: hidden;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
  color: rgb(0, 0, 1);
  background-color: #ffffff;
}
.layout-config.layout-config-active {
  transform: translate3d(0px, 0px, 0px);
}
.layout-config.layout-config-active .layout-config-button i {
  transform: rotate(360deg);
}
.layout-config .layout-config-button {
  display: block;
  position: absolute;
  width: 52px;
  height: 52px;
  line-height: 52px;
  background-color: #ffffff;
  text-align: center;
  color: rgb(0, 0, 1);
  top: 230px;
  left: -52px;
  z-index: -1;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.2s;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
}
.layout-config .layout-config-button i {
  font-size: 32px;
  line-height: inherit;
  cursor: pointer;
  transform: rotate(0deg);
  transition: color 0.2s, transform 1s;
}
.layout-config .layout-config-close {
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  right: 14px;
  top: 10px;
  z-index: 1;
  background-color: #e54a51;
  border-radius: 50%;
  transition: background-color 0.2s;
}
.layout-config .layout-config-close i {
  color: #ffffff;
  line-height: inherit;
  font-size: 12px;
}
.layout-config .layout-config-close:hover {
  background-color: #ea6e73;
}
.layout-config .layout-config-content {
  position: relative;
  height: 100%;
  padding: 1em;
  overflow: auto;
  padding-bottom: 3em;
}
.layout-config .layout-config-content .layout-themes {
  display: flex;
  flex-wrap: wrap;
}
.layout-config .layout-config-content .layout-themes > div {
  padding: 0.25rem;
}
.layout-config .layout-config-content .layout-themes a {
  border-radius: 4px;
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
}
.layout-config .layout-config-content .layout-themes a i {
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
.layout-config .layout-config-content .layout-themes a img {
  width: 3rem;
  height: 3rem;
}
.layout-config .layout-config-content .layout-themes a:hover {
  transform: scale(1.1);
}
.layout-config .p-col {
  text-align: center;
}
.layout-config p {
  line-height: 1.5;
  margin-top: 0;
  color: rgba(0, 0, 0, 0.6);
}

.blocked-scroll-config {
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .layout-config {
    transform: translate3d(100%, 0px, 0px);
  }
  .layout-config.layout-config-active {
    transform: translate3d(0px, 0px, 0px);
  }
  .layout-config .layout-config-close {
    right: 10px;
  }
}
.widget-overview {
  padding: 0 !important;
  min-height: 140px;
  position: relative;
  margin-bottom: 0 !important;
}
.widget-overview .overview-content {
  padding: 16px;
}
.widget-overview .overview-content .overview-title {
  font-size: 18px;
}
.widget-overview .overview-content .overview-badge {
  float: right;
  color: rgba(0, 0, 0, 0.6);
}
.widget-overview .overview-content .overview-detail {
  display: block;
  font-size: 24px;
  margin-top: 5px;
}
.widget-overview .overview-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.widget-overview .overview-footer img {
  display: block;
}

.widget-colorbox {
  padding: 0 !important;
  text-align: center;
  overflow: hidden;
  margin: 0 !important;
}
.widget-colorbox i {
  font-size: 36px;
  margin-top: 10px;
  color: #ffffff;
}
.widget-colorbox .colorbox-name {
  font-size: 20px;
  display: inline-block;
  width: 100%;
  margin: 4px 0 10px 0;
  color: #ffffff;
}
.widget-colorbox .colorbox-count {
  color: #ffffff;
  font-size: 36px;
}
.widget-colorbox .colorbox-count {
  font-weight: bold;
}
.widget-colorbox.colorbox-1 {
  background-color: #4caf50;
}
.widget-colorbox.colorbox-1 div:first-child {
  background-color: #2e7d32;
}
.widget-colorbox.colorbox-2 {
  background-color: #03a9f4;
}
.widget-colorbox.colorbox-2 div:first-child {
  background-color: #0277bd;
}
.widget-colorbox.colorbox-3 {
  background-color: #673ab7;
}
.widget-colorbox.colorbox-3 div:first-child {
  background-color: #4527a0;
}
.widget-colorbox.colorbox-4 {
  background-color: #009688;
}
.widget-colorbox.colorbox-4 div:first-child {
  background-color: #00695c;
}

.widget-timeline {
  height: 100%;
  box-sizing: border-box;
}
.widget-timeline > .p-grid .p-col-3 {
  font-size: 14px;
  position: relative;
  border-right: 1px solid #bdbdbd;
}
.widget-timeline > .p-grid .p-col-3 i {
  background-color: #ffffff;
  font-size: 28px;
  position: absolute;
  top: 0;
  right: -14px;
}
.widget-timeline > .p-grid .p-col-9 {
  padding-left: 1.5em;
}
.widget-timeline > .p-grid .p-col-9 .event-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  display: block;
  padding-bottom: 20px;
}
.widget-timeline > .p-grid .p-col-9 .event-content img {
  width: 100%;
}

.widget-user-card {
  padding: 0;
  text-align: center;
}
.widget-user-card .user-card-header {
  position: relative;
  height: 200px;
  background-size: cover;
}
.widget-user-card .user-card-avatar {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 8rem;
  height: 8rem;
  margin-left: -4rem;
  margin-bottom: -4rem;
  border-radius: 50%;
}
.widget-user-card .user-card-body {
  padding: 6em 2rem 2rem 2rem;
}
.widget-user-card .user-card-title {
  font-size: 1.5rem;
  font-weight: normal;
}
.widget-user-card .user-card-subtext {
  color: rgba(0, 0, 0, 0.6);
}
.widget-user-card .user-card-stats {
  margin-top: 2rem;
}
.widget-user-card .user-card-stats i {
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.6);
}
.widget-user-card .user-card-stats span {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.widget-contacts {
  overflow: hidden;
}
.widget-contacts > .p-panel {
  min-height: 340px;
}
.widget-contacts .p-panel-content {
  padding: 5px 0 10px 0 !important;
}
.widget-contacts ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.widget-contacts ul li {
  border-bottom: 1px solid #d8d8d8;
}
.widget-contacts ul li a {
  padding: 9px;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  display: block;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.widget-contacts ul li a .name {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
  color: rgb(0, 0, 1);
}
.widget-contacts ul li a .email {
  position: absolute;
  right: 10px;
  top: 30px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.widget-contacts ul li a:hover {
  cursor: pointer;
  background-color: #e8e8e8;
}
.widget-contacts ul li:last-child {
  border: 0;
}

.widget-pricing-card {
  text-align: center;
}
.widget-pricing-card .card {
  height: 100%;
  padding: 0;
}
.widget-pricing-card .pricing-header {
  padding: 20px 0;
  background-color: #3f51b5;
  color: #ffffff;
}
.widget-pricing-card .pricing-header span {
  display: block;
  line-height: 48px;
}
.widget-pricing-card .pricing-header span.name {
  font-weight: 300;
  font-size: 24px;
}
.widget-pricing-card .pricing-header span.fee {
  font-size: 48px;
  font-weight: 700;
}
.widget-pricing-card .pricing-header span.type {
  font-weight: 300;
  font-size: 16px;
}
.widget-pricing-card i {
  color: #3f51b5;
}
.widget-pricing-card .pricing-content ul {
  margin: 0;
  padding: 20px 20px;
  list-style-type: none;
}
.widget-pricing-card .pricing-content ul li {
  font-size: 18px;
  text-align: left;
  padding: 10px 14px;
}
.widget-pricing-card .pricing-content ul li i {
  margin-right: 20px;
  vertical-align: middle;
}
.widget-pricing-card .pricing-content ul li span {
  vertical-align: middle;
}

.widget-chat .p-panel-content {
  padding: 0 !important;
}
.widget-chat ul {
  padding: 12px;
  margin: 0;
  list-style-type: none;
}
.widget-chat ul li {
  padding: 6px 0;
}
.widget-chat ul li img {
  width: 36px;
  float: left;
}
.widget-chat ul li span {
  padding: 6px 12px;
  float: left;
  display: inline-block;
  margin: 4px 0;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.widget-chat ul li.message-from img,
.widget-chat ul li.message-from span {
  float: left;
}
.widget-chat ul li.message-from img {
  margin-right: 8px;
}
.widget-chat ul li.message-from span {
  background-color: #bbdefb;
  color: #000000;
}
.widget-chat ul li.message-own img,
.widget-chat ul li.message-own span {
  float: right;
}
.widget-chat ul li.message-own img {
  margin-left: 8px;
}
.widget-chat ul li.message-own span {
  background: #ffecb3;
  color: #000000;
}
.widget-chat .new-message {
  height: 40px;
  border-top: 1px solid #dce2e7;
  color: #afafc0;
}
.widget-chat .new-message .message-attachment {
  display: inline-block;
  border-right: 1px solid #dce2e7;
  width: 40px;
  line-height: 40px;
  height: 100%;
  text-align: center;
}
.widget-chat .new-message .message-attachment i {
  line-height: inherit;
  font-size: 24px;
}
.widget-chat .new-message .message-input {
  position: relative;
  top: -4px;
  width: calc(100% - 100px);
  display: inline-block;
}
.widget-chat .new-message .message-input input {
  border: 0 none;
  font-size: 14px;
  width: 100%;
  background-color: transparent;
  outline: 0 none;
  color: rgba(0, 0, 0, 0.6);
}

.widget-activity-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.widget-activity-list li {
  border-bottom: 1px solid #bdbdbd;
  padding: 16px 8px;
}
.widget-activity-list li .count {
  font-size: 20px;
  color: #ffffff;
  background-color: #007be5;
  font-weight: 700;
  padding: 0.25em 0.5em;
  display: inline-block;
  border-radius: 4px;
}

/* Add your customizations of layout here */