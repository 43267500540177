@import '../variables';
@import '../layout/mixins';

// Custom
$white: #fff;
$black: #000001;
$red: #f44336;
$textColor: #212121;

$borderRadius: 3px;
$dividerLightColor: #cacaca;
$dataTableRowBgColorEven: #f4f4f4;
$inputBorderErrorColor: #e62a10;
$inputErrorTextColor: #e62a10;
