// Prime doens't have standalone badges
.badge {
    display: inline-block;
    width: 7rem;
    padding: 0.2rem 0.5rem;
    border-radius: $borderRadius;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;

    @mixin badge($color, $lighten: 35%) {
        background-color: lighten($color, $lighten);
        color: $color;
    }

    &.badge-info {
        @include badge(#33428a);
    }

    &.badge-success {
        @include badge($primaryColor, 50%);
    }

    &.badge-warning {
        @include badge(#e7803f);
    }

    &.badge-mute {
        @include badge(#51626f);
    }
}
