.p-autocomplete {
    .p-autocomplete-loader {
        right: nth($inputPadding, 2);
    }

    &.p-autocomplete-dd {
        .p-autocomplete-loader {
            right: nth($inputPadding, 2) + $buttonIconOnlyWidth;
        }
    }

    &.p-autocomplete-multiple {
        .p-autocomplete-multiple-container {
            padding: nth($inputPadding, 1) * 0.5 nth($inputPadding, 2);

            &:not(.p-disabled):hover {
                border-color: $inputHoverBorderColor;
            }

            &:not(.p-disabled).p-focus {
                @include focused-input();
            }

            .p-autocomplete-input-token {
                padding: nth($inputPadding, 1) * 0.5 0;

                input {
                    font-family: $fontFamily;
                    font-size: $fontSize;
                    color: $textColor;
                    padding: 0;
                    margin: 0;
                }
            }

            .p-autocomplete-token {
                padding: nth($inputPadding, 1) * 0.5 nth($inputPadding, 2);
                margin-right: $inlineSpacing;
                background: $highlightBg;
                color: $highlightTextColor;
                border-radius: $borderRadius;

                .p-autocomplete-token-icon {
                    margin-left: $inlineSpacing;
                }
            }
        }
    }

    &.p-error > .p-inputtext,
    &.p-invalid > .p-inputtext {
        @include invalid-input();
    }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    @include invalid-input();
}

.p-autocomplete-panel {
    background: $inputOverlayBg;
    color: $inputListTextColor;
    border: $inputOverlayBorder;
    border-radius: $borderRadius;
    box-shadow: $inputOverlayShadow;

    .p-autocomplete-items {
        padding: $inputListPadding;

        .p-autocomplete-item {
            margin: $inputListItemMargin;
            padding: $inputListItemPadding;
            border: $inputListItemBorder;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
            transition: $listItemTransition;
            border-radius: $inputListItemBorderRadius;

            &:hover {
                color: $inputListItemTextHoverColor;
                background: $inputListItemHoverBg;
            }

            &.p-highlight {
                color: $highlightTextColor;
                background: $highlightBg;
            }
        }
    }
}
