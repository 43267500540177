div.tooltip.gantt-chart-tooltip {
    height: auto;
    opacity: 0;
    padding: 10px;

    .gantt-chart-tooltip-body {
        height: 100%;
        padding: 10px;
        padding-top: 15px;
        color: #ffffff;
        padding: 10px;

        .gantt-chart-title {
            padding-bottom: 20px;
            text-align: left;
            font-weight: bold;
        }

        .gantt-chart-row {
            &:not(:last-child) {
                padding-bottom: 10px;
            }

            &.gantt-chart-border {
                border-top: 1px solid #aaaaaa;
            }

            .text-left {
                text-align: left;
            }

            .text-right {
                text-align: right;
            }
        }
    }
}
