@import './custom-variables';

@import './progress-spinner.scss';
@import './badge';
@import './breadcrumb.scss';
@import './buttons';
@import './dialog';
@import './error';
@import './list';
@import './panel';
@import './table';
@import './splash';
@import './text';
@import './gantt-chart';

body {
    font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif' !important;
}
