.layout-config {
    position: fixed;
    top: 75px;
    padding: 0;
    right: 0;
    display: block;
    width: 16em;
    z-index: 998;
    height: calc(100% - 50px);
    transform: translate3d(16em, 0px, 0px);
    transition: transform $transitionDuration;
    backface-visibility: hidden;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
    color: $textColor;
    background-color: #ffffff;

    &.layout-config-active {
        transform: translate3d(0px, 0px, 0px);

        .layout-config-button {
            i {
                transform: rotate(360deg);
            }
        }
    }

    .layout-config-button {
        display: block;
        position: absolute;
        width: 52px;
        height: 52px;
        line-height: 52px;
        background-color: #ffffff;
        text-align: center;
        color: $textColor;
        top: 230px;
        left: -52px;
        z-index: -1;
        overflow: hidden;
        cursor: pointer;
        transition: background-color $transitionDuration;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);

        i {
            font-size: 32px;
            line-height: inherit;
            cursor: pointer;
            transform: rotate(0deg);
            transition: color $transitionDuration, transform 1s;
        }
    }

    .layout-config-close {
        position: absolute;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        right: 14px;
        top: 10px;
        z-index: 1;
        background-color: #e54a51;
        border-radius: 50%;
        transition: background-color $transitionDuration;

        i {
            color: #ffffff;
            line-height: inherit;
            font-size: 12px;
        }

        &:hover {
            background-color: #ea6e73;
        }
    }

    .layout-config-content {
        position: relative;
        height: 100%;
        padding: 1em;
        overflow: auto;
        padding-bottom: 3em;

        .layout-themes {
            display: flex;
            flex-wrap: wrap;

            > div {
                padding: 0.25rem;
            }

            a {
                border-radius: $borderRadius;
                display: block;
                position: relative;
                align-items: center;
                justify-content: center;
                transition: transform $transitionDuration;

                i {
                    font-size: 1rem;
                    font-weight: 600;
                    position: absolute;
                    color: $primaryTextColor;
                    top: 50%;
                    left: 50%;
                    margin-left: -0.5rem;
                    margin-top: -0.5rem;
                }

                img {
                    width: 3rem;
                    height: 3rem;
                }

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    .p-col {
        text-align: center;
    }

    p {
        line-height: 1.5;
        margin-top: 0;
        color: $textSecondaryColor;
    }
}

.blocked-scroll-config {
    overflow: hidden;
}

@media screen and (max-width: 1024px) {
    .layout-config {
        transform: translate3d(100%, 0px, 0px);

        &.layout-config-active {
            transform: translate3d(0px, 0px, 0px);
        }

        .layout-config-close {
            right: 10px;
        }
    }
}
