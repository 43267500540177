.checkbox-column {
    width: 1.5rem;
    padding: 0.5rem !important;
}

// Use for filtering data in tables
.search-filter-data {
    padding: 1.5rem 0;

    .search-filter-data-label {
        font-weight: bold;
    }

    p-checkbox {
        margin-left: 1rem;
    }
}

.table-cell-status {
    width: 15%;

    .badge {
        width: 5rem;
    }
}

.table-cell-email,
.table-cell-name {
    width: 30%;

    > span {
        display: inline-grid;

        .table-cell {
            width: 100%;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
