.splash-screen {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #f7f7f7;
    height: 100%;
    width: 100%;
    z-index: 20000;
}

.splash-screen .splash-container {
    padding-top: 250px;
    width: 250px;
    margin: 0 auto;
    text-align: center;
}

.splash-screen .logo {
    display: inline-block;
    vertical-align: middle;
    width: 182px;
    height: 33px;
    background: url('/assets/layout/images/buck-e-logo.svg') top left no-repeat;
    background-size: 182px 33px;
}

.splash-screen .load-bar {
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: 6px;
    background-color: #f03241;
}

.splash-screen .bar {
    content: '';
    display: inline-block;
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
}

.splash-screen .bar:nth-child(1) {
    background-color: #f03241;
    animation: loading 2.5s linear infinite;
}

.splash-screen .bar:nth-child(2) {
    background-color: #999;
    animation: loading 2.5s linear 1s infinite;
}

@keyframes loading {
    from {
        left: 50%;
        width: 0;
        z-index: 100;
    }

    33.3333% {
        left: 0;
        width: 100%;
        z-index: 10;
    }

    to {
        left: 0;
        width: 100%;
    }
}
