.dashboard {
    .overview {
        padding: 0 !important;
        min-height: 140px;
        position: relative;
        margin-bottom: 0 !important;

        .overview-content {
            padding: 16px;

            .overview-title {
                font-size: 18px;
            }

            .overview-badge {
                float: right;
                color: $textSecondaryColor;
            }

            .overview-detail {
                display: block;
                font-size: 24px;
                margin-top: 5px;
            }
        }

        .overview-footer {
            position: absolute;
            bottom: 0;
            width: 100%;

            img {
                display: block;
            }
        }
    }

    .colorbox {
        padding: 0 !important;
        text-align: center;
        overflow: hidden;
        margin: 0 !important;

        i {
            font-size: 36px;
            margin-top: 10px;
            color: #ffffff;
        }

        .colorbox-name {
            font-size: 20px;
            display: inline-block;
            width: 100%;
            margin: 4px 0 10px 0;
            color: #ffffff;
        }

        .colorbox-count {
            color: #ffffff;
            font-size: 36px;
        }

        .colorbox-count {
            font-weight: bold;
        }

        &.colorbox-1 {
            background-color: #4caf50;

            div:first-child {
                background-color: #2e7d32;
            }
        }

        &.colorbox-2 {
            background-color: #03a9f4;

            div:first-child {
                background-color: #0277bd;
            }
        }

        &.colorbox-3 {
            background-color: #673ab7;

            div:first-child {
                background-color: #4527a0;
            }
        }

        &.colorbox-4 {
            background-color: #009688;

            div:first-child {
                background-color: #00695c;
            }
        }
    }

    .task-list {
        overflow: hidden;

        > .p-panel {
            min-height: 340px;
        }

        .p-panel-content {
            padding: 10px 0 !important;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                padding: $inputOptionPadding;
                border-bottom: 1px solid #dbdbdb;
            }

            .p-checkbox {
                vertical-align: middle;
                margin-right: 5px;
            }

            .task-name {
                vertical-align: middle;
            }

            i {
                color: $textSecondaryColor;
                float: right;
                font-size: 1.25em;
            }
        }
    }

    .contact-form {
        overflow: hidden;

        .p-panel {
            min-height: 340px;
        }

        .p-button {
            margin-top: 10px;
        }
    }

    .contacts {
        overflow: hidden;

        > .p-panel {
            min-height: 340px;
        }

        .p-panel-content {
            padding: 5px 0 10px 0 !important;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                border-bottom: 1px solid #d8d8d8;

                a {
                    padding: 9px;
                    width: 100%;
                    box-sizing: border-box;
                    text-decoration: none;
                    position: relative;
                    display: block;
                    @include border-radius(2px);
                    @include transition(background-color 0.2s);

                    .name {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        font-size: 18px;
                        color: $textColor;
                    }

                    .email {
                        position: absolute;
                        right: 10px;
                        top: 30px;
                        font-size: 14px;
                        color: $textSecondaryColor;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: #e8e8e8;
                    }
                }

                &:last-child {
                    border: 0;
                }
            }
        }
    }

    .activity-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            border-bottom: 1px solid $dividerColor;
            padding: 16px 8px;

            .count {
                font-size: 20px;
                color: #ffffff;
                background-color: #007be5;
                font-weight: 700;
                padding: 0.25em 0.5em;
                display: inline-block;
                border-radius: $borderRadius;
            }
        }
    }

    .timeline {
        height: 100%;
        box-sizing: border-box;

        > .p-grid {
            .p-col-3 {
                font-size: 14px;
                position: relative;
                border-right: 1px solid #bdbdbd;

                i {
                    background-color: #ffffff;
                    font-size: 28px;
                    position: absolute;
                    top: 0;
                    right: -14px;
                }
            }

            .p-col-9 {
                padding-left: 1.5em;
                .event-owner {
                }

                .event-text {
                    color: $textSecondaryColor;
                    font-size: 14px;
                    display: block;
                    padding-bottom: 20px;
                }

                .event-content {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    > div {
        > .p-panel {
            @include content-shadow();
        }
    }
}

.layout-rightpanel {
    .layout-rightpanel-header {
        background: url('/assets/layout/images/dashboard/sidebar-image.jpg') no-repeat;
        background-size: cover;
        height: 118px;
        padding: 20px 14px;
        @include box-sizing(border-box);

        .weather-day,
        .weather-date {
            color: #ffffff;
            left: 14px;
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 4px;
        }
    }

    .layout-rightpanel-content {
        padding: 14px;

        h1 {
            font-size: 18px;
            margin: 0 0 4px 0;
        }

        h2 {
            font-size: 16px;
            margin: 0;
            color: $textSecondaryColor;
            font-weight: normal;
        }

        .weather-today {
            text-align: center;
            margin-top: 28px;

            .weather-today-value {
                font-size: 36px;
                vertical-align: middle;
                margin-right: 14px;
            }

            img {
                vertical-align: middle;
            }
        }

        .weekly-weather {
            list-style-type: none;
            margin: 28px 0 0 0;
            padding: 0;

            li {
                padding: 8px 14px;
                border-bottom: 1px solid #d8dae2;
                position: relative;

                .weekly-weather-value {
                    position: absolute;
                    right: 40px;
                }

                img {
                    width: 24px;
                    position: absolute;
                    right: 0px;
                    top: 4px;
                }
            }
        }
    }
}
