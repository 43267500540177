.widget-overview {
    padding: 0 !important;
    min-height: 140px;
    position: relative;
    margin-bottom: 0 !important;

    .overview-content {
        padding: 16px;

        .overview-title {
            font-size: 18px;
        }

        .overview-badge {
            float: right;
            color: $textSecondaryColor;
        }

        .overview-detail {
            display: block;
            font-size: 24px;
            margin-top: 5px;
        }
    }

    .overview-footer {
        position: absolute;
        bottom: 0;
        width: 100%;

        img {
            display: block;
        }
    }
}

.widget-colorbox {
    padding: 0 !important;
    text-align: center;
    overflow: hidden;
    margin: 0 !important;

    i {
        font-size: 36px;
        margin-top: 10px;
        color: #ffffff;
    }

    .colorbox-name {
        font-size: 20px;
        display: inline-block;
        width: 100%;
        margin: 4px 0 10px 0;
        color: #ffffff;
    }

    .colorbox-count {
        color: #ffffff;
        font-size: 36px;
    }

    .colorbox-count {
        font-weight: bold;
    }

    &.colorbox-1 {
        background-color: #4caf50;

        div:first-child {
            background-color: #2e7d32;
        }
    }

    &.colorbox-2 {
        background-color: #03a9f4;

        div:first-child {
            background-color: #0277bd;
        }
    }

    &.colorbox-3 {
        background-color: #673ab7;

        div:first-child {
            background-color: #4527a0;
        }
    }

    &.colorbox-4 {
        background-color: #009688;

        div:first-child {
            background-color: #00695c;
        }
    }
}

.widget-timeline {
    height: 100%;
    box-sizing: border-box;

    > .p-grid {
        .p-col-3 {
            font-size: 14px;
            position: relative;
            border-right: 1px solid #bdbdbd;

            i {
                background-color: #ffffff;
                font-size: 28px;
                position: absolute;
                top: 0;
                right: -14px;
            }
        }

        .p-col-9 {
            padding-left: 1.5em;
            .event-owner {
            }

            .event-text {
                color: $textSecondaryColor;
                font-size: 14px;
                display: block;
                padding-bottom: 20px;
            }

            .event-content {
                img {
                    width: 100%;
                }
            }
        }
    }
}

.widget-user-card {
    padding: 0;
    text-align: center;

    .user-card-header {
        position: relative;
        height: 200px;
        // background-image: url("../../demo/images/widgets/user-card.jpg");
        background-size: cover;
    }

    .user-card-avatar {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 8rem;
        height: 8rem;
        margin-left: -4rem;
        margin-bottom: -4rem;
        border-radius: 50%;
    }

    .user-card-body {
        padding: 6em 2rem 2rem 2rem;
    }

    .user-card-title {
        font-size: 1.5rem;
        font-weight: normal;
    }

    .user-card-subtext {
        color: $textSecondaryColor;
    }

    .user-card-stats {
        margin-top: 2rem;

        i {
            font-size: 2rem;
            color: $textSecondaryColor;
        }

        span {
            font-size: 1.25rem;
            font-weight: 600;
            margin-top: 0.5rem;
        }
    }
}

.widget-contacts {
    overflow: hidden;

    > .p-panel {
        min-height: 340px;
    }

    .p-panel-content {
        padding: 5px 0 10px 0 !important;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            border-bottom: 1px solid #d8d8d8;

            a {
                padding: 9px;
                width: 100%;
                box-sizing: border-box;
                text-decoration: none;
                position: relative;
                display: block;
                @include border-radius(2px);
                @include transition(background-color 0.2s);

                .name {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    font-size: 18px;
                    color: $textColor;
                }

                .email {
                    position: absolute;
                    right: 10px;
                    top: 30px;
                    font-size: 14px;
                    color: $textSecondaryColor;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #e8e8e8;
                }
            }

            &:last-child {
                border: 0;
            }
        }
    }
}

.widget-pricing-card {
    text-align: center;

    .card {
        height: 100%;
        padding: 0;
    }

    .pricing-header {
        padding: 20px 0;
        background-color: #3f51b5;
        color: #ffffff;

        span {
            display: block;
            line-height: 48px;

            &.name {
                font-weight: 300;
                font-size: 24px;
            }

            &.fee {
                font-size: 48px;
                font-weight: 700;
            }

            &.type {
                font-weight: 300;
                font-size: 16px;
            }
        }
    }

    i {
        color: #3f51b5;
    }

    .pricing-content {
        ul {
            margin: 0;
            padding: 20px 20px;
            list-style-type: none;

            li {
                font-size: 18px;
                text-align: left;
                padding: 10px 14px;

                i {
                    margin-right: 20px;
                    vertical-align: middle;
                }

                span {
                    vertical-align: middle;
                }
            }
        }
    }
}

.widget-chat {
    .p-panel-content {
        padding: 0 !important;
    }
    ul {
        padding: 12px;
        margin: 0;
        list-style-type: none;

        li {
            padding: 6px 0;
            img {
                width: 36px;
                float: left;
            }

            span {
                padding: 6px 12px;
                float: left;
                display: inline-block;
                margin: 4px 0;
                @include border-radius(10px);
            }

            &.message-from {
                img,
                span {
                    float: left;
                }

                img {
                    margin-right: 8px;
                }

                span {
                    background-color: #bbdefb;
                    color: #000000;
                }
            }

            &.message-own {
                img,
                span {
                    float: right;
                }

                img {
                    margin-left: 8px;
                }

                span {
                    background: #ffecb3;
                    color: #000000;
                }
            }
        }
    }

    .new-message {
        height: 40px;
        border-top: 1px solid #dce2e7;
        color: #afafc0;

        .message-attachment {
            display: inline-block;
            border-right: 1px solid #dce2e7;
            width: 40px;
            line-height: 40px;
            height: 100%;
            text-align: center;

            i {
                line-height: inherit;
                font-size: 24px;
            }
        }

        .message-input {
            position: relative;
            top: -4px;
            width: calc(100% - 100px);
            display: inline-block;

            input {
                border: 0 none;
                font-size: 14px;
                width: 100%;
                background-color: transparent;
                outline: 0 none;
                color: $textSecondaryColor;
            }
        }
    }
}

.widget-activity-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        border-bottom: 1px solid $dividerColor;
        padding: 16px 8px;

        .count {
            font-size: 20px;
            color: #ffffff;
            background-color: #007be5;
            font-weight: 700;
            padding: 0.25em 0.5em;
            display: inline-block;
            border-radius: $borderRadius;
        }
    }
}
