body {
    .p-button {
        &:focus {
            background-color: $primaryColor;
        }

        &:active {
            background-color: #8c193c;
        }

        // Allows using material icons inside pButton
        &.p-button-icon-only {
            width: 2rem;
            line-height: 2rem;

            &:hover {
                .material-icons {
                    color: lighten(#8c193c, 0.1);
                }
            }

            &:disabled {
                color: $primaryColor !important;
                opacity: 0.35;
                filter: Alpha(Opacity=35);
                background-color: transparent !important;
                cursor: auto !important;
            }

            .material-icons {
                left: -0.75rem;
                line-height: 1;
                vertical-align: middle;

                &:hover {
                    background-color: transparent;
                }
            }
        }

        // Icon only buttons don't support rounded only
        &.p-button-icon-only-rounded {
            // height: 2.25rem !important;
            // width: 2.25rem !important;
            border-radius: $borderRadius !important;

            .material-icons {
                left: -0.55rem;
                line-height: 1.1rem;
            }
        }

        // Transparent button
        &.transparent,
        &.p-button-transparent {
            background-color: transparent;
            color: $primaryColor;
            box-shadow: none;

            &:enabled:not(:focus):hover {
                color: $primaryDarkColor;
                background: transparent;
            }

            &:focus {
                outline: 0 none;
                background-color: darken($white, 10%);
            }
        }
    }

    .p-fileupload-choose:not(.p-disabled) {
        &:focus.p-button {
            background-color: $primaryColor;
        }

        &:active.p-button {
            background-color: #8c193c;
        }
    }

    .header-actions {
        display: flex;
        justify-content: flex-end;

        .p-button {
            margin-left: 0.5rem;
        }
    }
}
