.fp-spinner .p-progress-spinner-circle {
    animation: fp-progress-spinner-dash 1.5s ease-in-out infinite, fp-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes fp-progress-spinner-color {
    100%,
    0% {
        stroke: #c30045;
    }
    40% {
        stroke: #792844;
    }
    66% {
        stroke: #f03241;
    }
    80%,
    90% {
        stroke: #c30045;
    }
}

@keyframes fp-progress-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
