p-panel {
    display: block;
    margin-bottom: 1.5rem;

    &:last-child {
        margin-bottom: 0;
    }
}

p-panel .p-panel-list {
    padding: 1rem 0;
}

.p-panel .p-panel-header {
    background-color: $primaryColor;
    color: $white;
    font-size: 1em;
    border: 1px solid $primaryColor;

    .p-panel-header-icon {
        color: $white;

        &:enabled:hover {
            color: $white;
        }
    }
}
