.p-steps {
    padding: 1rem 0;

    .p-steps-item {
        justify-content: flex-start;

        &:before {
            margin-top: 0;
        }

        .p-menuitem-link {
            flex-direction: row;
            background-color: transparent;
            z-index: 1;

            .p-steps-number {
                background-color: #9e9d9e;
                color: #ffffff;
                margin-right: $inlineSpacing;
            }

            .p-steps-title {
                margin: 0;
                background-color: transparent;
                color: #9e9d9e;
                padding: 0 0.25rem 0 0;
            }
        }

        &.p-highlight {
            .p-steps-number {
                background-color: $primaryColor;
                color: $primaryTextColor;
            }

            .p-steps-title {
                font-weight: 600;
                color: $textColor;
            }
        }

        &.p-disabled {
            opacity: 1;
        }
    }
}
