.p-inputtext {
    &:enabled:focus {
        box-shadow: inset 0 0 0 1px $inputFocusBorderColor, inset 0 0 0 1px $inputFocusBorderColor, inset 0 0 0 1px $inputFocusBorderColor,
            inset 0 0 0 1px $inputFocusBorderColor;
    }
}

.p-input-filled {
    .p-inputtext {
        @include filled-input();
    }
}
