$primaryColor: #c30045;
$primaryDarkColor: $primaryColor;
$primaryLightColor: #9fa8da;
$primaryTextColor: #ffffff;
$accentColor: #f03241;
$accentDarkColor: #ff8f00;
$accentLightColor: #ffe082;
$accentTextColor: #ffffff;
$darkMenuBgColor: #424242;
$darkMenuHoverColor: #676767;
$darkMenuRouterLinkActiveColor: #ffffff;
$lightMenuRouterLinkActiveColor: #ffffff;
$horizontalLightMenuRouterLinkActiveColor: #ffffff;
