$sm: 640px;
$md: 720px;
$lg: 960px;
$xl: 1080px;

h1,
h2,
h3,
h4,
h5 {
    color: $primaryColor;
}

.p-text-primary {
    color: $primaryColor;
}

@mixin text-alignment {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }
}

.p {
    &-sm-text {
        @media (max-width: ($sm - 1px)) {
            @include text-alignment();
        }
    }

    &-md-text {
        @media (min-width: $md) {
            @include text-alignment();
        }
    }

    &-lg-text {
        @media (min-width: $lg) {
            @include text-alignment();
        }
    }

    &-xl-text {
        @media (min-width: $xl) {
            @include text-alignment();
        }
    }
}
